.footer {
  padding-top: 3.5rem !important;
  padding-bottom: 1rem;
  //background-color: var(--BlackColor);
  color: #fff;
}
.lead {
    font-size: 0.7rem;
}
.btn-social {
    display: inline;
}
@media only screen and (max-width:992px){
  .footer {
    padding-top: 1.5rem;
    padding-bottom: 0.5rem;
  }
  .mb-5 {
    margin-bottom: 0  rem !important;
  }
  .lead {
    font-size: 0.5rem !important;
    margin-bottom: 5px;
  }
  #newLogo{
    width: 100px;
  }
  .btn-outline-light {
    padding: 0px;
    border-color: black;
  }
}