#partners{
    height: 100px !important;
}
.inicio {
    width: 70% !important;
}
@media only screen and (max-width:992px){
    .inicio {
        width: 100% !important;
    }
}
.row-inicio {
    justify-content: center;
}
