.meses {
    flex-direction: row;
    display: flex;
    justify-content: center;
}
.eventos {
    flex-direction: row;
    display: flex;
    padding-top: 10px;
}
.eventos-mes {
    font-size: 72px;
    font-family: 'YRT Lucky Dog' !important;
    width: 270px;
    height: 200px;
    margin-right: 15px;
}
.eventos-mes-mobile {
    display: none;
}
.artista-nombre {
    font-size: 25px;
    font-family: 'Montserrat';
    font-weight:700;
}
.evento-1 {
    width: 230px;
    word-wrap: break-word;
    margin-right: 30px;
    
}
.meses {
    margin-bottom: 40px;
    justify-content: flex-start;
}
.lugar-nombre {
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    padding-left: 5px;
}
.lugar-fecha {
    background-color: white;
    color: black;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    padding-left: 5px;
}
.lugar-div {
    display: flex;
    align-items: end;
    flex-direction: row;
    align-items: center;
}
a {
    width: 100%;
    display: contents;
    
}
.lugar-div-entrada:hover {
    opacity: 70%;
}
@media only screen and (max-width:992px){
    .meses {
        display: flex;
        flex-direction: column;
    }
    .eventos-mes-mobile {
        font-size: 45px !important;
        height: 50px !important;
        text-align: center;
        width: auto !important;
        margin-right: 0px !important;
        display: block;
        font-family: 'YRT Lucky Dog' !important;
    }
    .eventos-mes{
        display: none;
    }
    .artista-nombre {
        font-size: 22px;
    }
    .evento-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: inherit;
        margin-right: 0px;
    }
    .eventos {
        display: grid;
    }
}

.img-shows {
    margin-bottom: 10px;
}
.lugar {
    width: 220px;
}