.title-artist {
    font-family: var(--Principal-fuente) !important;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#icon:hover{
    color: white;
    transition:120ms ease-in;
}
.redes-sociales {
    width: 65%;
    margin-bottom: 20px;
}

@media only screen and (max-width:992px){
    .redes-sociales {
        width: 50%;
    }
    .img-fluid {
        max-width: 60%;
    }
}
a:hover {
    opacity: 70%;
}