.row-nosotros {
    justify-content: center;
}
.nosotros {
    width: 55%;
}
.col-lg-3 > img{
    margin-bottom: 20px;
}

.justify-content-center {
    justify-content: space-around !important;
}

@media only screen and (max-width:992px){
    .nosotros {
        width: 100%; 
    }
    .container {
        padding-left: 3%;
        padding-right: 3%;
    }
    p {
        font-size: 12px !important;
    }
    .page-section {
        padding-top: 25px !important;
    }
    .mb-4 {
        margin-bottom: 0.5rem !important;
    }
    .card-title {
        font-size: 16px;
    }
    .card-text {
        font-size: 12px
    }
    .row {
        padding-right: 0px;
        padding-left: 0px;
    }
}