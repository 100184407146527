.btn-convocatoria  {
    background-color: black;
    color: white;
    border: 1px solid white;
    font-family: var(--Secundaria-fuente);
    margin-bottom: 30px !important;
}
.modalContainer {
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
}
#modal1{
    z-index: 20;
}

.img-modal, #submitModal {
    margin: 10px;
}

.modal-header, .modal-footer {
    background: #000000cf;
}
#modal-button {
    color: white !important;
    background-color: black !important;
    border-color: white;
}
.btn-close {
    background-color: white;
}
.img-modal {
    margin-top: 30px !important;
}
